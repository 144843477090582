import * as React from "react";
import Avatar from "@mui/material/Avatar";
import styled from "styled-components";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PriceItem from "../components/PriceItem";
import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socketConnect } from "../redux/socket/socketAction";
import BidAskPriceCard from "../components/BidAskPriceCard";
import TodayCalender from "../components/TodayCalender";
import CurrentTime from "../components/CurrentTime";
import TimeFrame from "../components/TimeFrame";
import HeadingLine from "../components/HeadingLine";
const SpotPage = () => {
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.socketReducer);
  useEffect(() => {
    dispatch(socketConnect());
  }, []);

  const dollarRate = 3.67;

  const addPointToAskPrice = 0.8;

  const [todayHigh, setTodayHigh] = useState(0);
  const [todayLow, setTodayLow] = useState(0);
  const [askPrice, setAskPrice] = useState(0);
  const [bidPrice, setBidPrice] = useState(0);
  const [k24gold, setK24Gold] = useState(0);
  const [k22gold, setK22Gold] = useState(0);
  const [k24Aed, setK24Aed] = useState(0);
  const [k22Aed, setK22Aed] = useState(0);

  useEffect(() => {
    if (socket) {
      socket.emit("subscribe_gold_price");
      socket.on("gold_price_update", (response) => {
        const data = JSON.parse(response);
        const todayData = data["data"]["today"];
        const gold_tickData = data["data"]["gold_tick"];

       // setNewData(gold_tickData);

        const askPrice__ = gold_tickData.ask;
        const bidPrice__ = gold_tickData.bid;
        setBidPrice(bidPrice__);
        setAskPrice(Number(bidPrice__)+.80);
       
        setTodayHigh(todayData.max);
        setTodayLow(todayData.min);
        const dollar1Gram = data["data"]["dollor"];

        const k24 = (askPrice__ + addPointToAskPrice) / 31.1035;
        const k22 = dollar1Gram["22K"];

        setK24Aed(k24 * dollarRate);
        setK22Aed(k22 * dollarRate);

        

        // // price up or down
        // setGoldData({
        //   k22: {
        //     status: k22Aed > goldData.k22.price ? "up" : "down",
        //     price: k22Aed,
        //   },
        //   k24: {
        //     status: k22Aed > goldData.k24.price ? "up" : "down",
        //     price: k24Aed,
        //   },
        //   ask: {
        //     status: gold_tickData.ask > goldData.ask.price ? "up" : "down",
        //     price: gold_tickData.ask,
        //   },
        //   bid: {
        //     status: gold_tickData.bid > goldData.bid.price ? "up" : "down",
        //     price: gold_tickData.bid,
        //   },
        //   min: todayData.min,
        //   max: todayData.max,
        // });
      });
    }
  }, [socket]);

  return (
    <>
      {/* <h1>{socket != null ? "Connected" : "contacting..."}</h1> */}
      <AppBarWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "1px",
          }}>
          {/* time */}
          <CalendarMonthIcon sx={{ fontSize: 30 }} />
          <TodayCalender />
        </div>

        <div>
          {/* logo */}
          <img src="logo.png" alt="logo" style={{ width: "100px" }} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            lineHeight: "1px",
          }}>
          {/* time */}
          <QueryBuilderIcon sx={{ fontSize: 30 }} />
          <CurrentTime />
        </div>
      </AppBarWrapper>

      <TimeFrame />

      <div style={{ height: "20px" }}></div>

      {bidPrice > 0 && (
        <BidAskPriceCard
          askPrice={askPrice.toFixed(2)}
          bidPrice={bidPrice}
          todayHigh={todayHigh}
          todayLow={todayLow}
        />
      )}

      <div style={{ height: "20px" }}></div>

      <HeadingLine />

      <div style={{ height: "5px" }}></div>

      <PriceItem
        name={"GOLD"}
        subName={"9999"}
        weight={"1GM"}
        status={"up"}
        price={String(k24Aed.toFixed(2))}
      />
      <PriceItem
        name={"GOLD"}
        subName={"TEN TOLA"}
        weight={"TTB"}
        status={"up"}
        price={String((k24Aed * 116.64).toFixed(2))}
      />
      <PriceItem
        name={"GOLD"}
        subName={"9999"}
        weight={"1KG"}
        status={"none"}
        price={String((k24Aed * 1000).toFixed(2))}
      />
      <PriceItem
        name={"GOLD"}
        subName={"995"}
        weight={"1KG"}
        status={"none"}
        price={String((k24Aed * 1000).toFixed(2))}
      />
    </>
  );
};

const AppBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
`;

// cardview
const CardView = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px #000;
  text-align: center;
  justify-content: space-between;
  align-items: center;
`;

// cardview
const CardViewSimple = styled.div`
  display: flex;
  background-color: #ac9107;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px #000;
  text-align: center;
  justify-content: space-between;
  align-items: center;
`;

const ItemCard = styled.div`
  width: 100%;
`;

export default SpotPage;
