import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const AdminLayout = () => {

  useEffect(() => {

    // check path using react router dom
    

    // localStorage.getItem('user') ? console.log('user logged in') : window.location.href = '/admin/login'

  }, [])


  return (
    <div>
        <Outlet />
    </div>
  )
}

export default AdminLayout